
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast } from "vant";
import { roomRent } from "@/api";

@Component
export default class Rent extends Vue {
    @Prop(Boolean) visible = false;
    @Prop(Object) room!: any;

    get show(): boolean {
        return this.visible;
    }
    set show(v) {
        this.$emit("update:visible", false);
    }
    get rent() {
        return this.room.user ? this.room.user.real_rent : this.room.rent;
    }

    private rent_month = 1;
    async onSubmit() {
        await roomRent({ id: this.room.id, rent_month: this.rent_month });
        Toast.success("收租成功");
        this.show = false;
        this.$emit("success");
    }
}
