
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { roomCost } from "@/api";

@Component
export default class Cost extends Vue {
    @Prop(Boolean) visible = false;
    @Prop(Object) room!: any;

    get show(): boolean {
        return this.visible;
    }
    set show(v) {
        this.$emit("update:visible", false);
    }

    private form = {
        water: 0,
        electric: 0,
    };

    @Watch("room", { deep: true, immediate: true }) handler(room: any) {
        room.water && this.$set(this.form, "water", room.water);
        room.electric && this.$set(this.form, "electric", room.electric);
    }
    async onSubmit() {
        await roomCost({ ...this.form, id: this.room.id });
        Toast.success("水电费收取成功");
        this.show = false;
        this.$emit("success");
    }
}
