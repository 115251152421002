
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { roomRentUpdate } from "@/api";

@Component
export default class RentUpdate extends Vue {
    @Prop(Boolean) visible = false;
    @Prop(Object) room!: any;

    get show(): boolean {
        return this.visible;
    }
    set show(v) {
        this.$emit("update:visible", false);
    }
    get rent() {
        return this.room.user ? this.room.user.real_rent : this.room.rent;
    }

    private update = "";

    @Watch("room", { deep: true, immediate: true }) handler(room: any) {
        this.update = this.rent;
    }
    async onSubmit() {
        await roomRentUpdate({ rent: this.update, id: this.room.id });
        Toast.success("月租修改成功");
        this.show = false;
        this.$emit("success");
    }
}
