
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Toast } from "vant";
import { roomStay } from "@/api";
import _date from "@wedjs/date";

@Component
export default class Stay extends Vue {
    @Prop(Boolean) visible = false;
    @Prop(Object) room!: any;

    private showCalendar = false;
    private form = {
        water: 0,
        electric: 0,
        deposit: "",
        rent: "",
        rent_month: 1,
        stay_at: _date.date("Y-m-d"),
        remark: "",
    };

    get show(): boolean {
        return this.visible;
    }
    set show(v) {
        this.$emit("update:visible", false);
    }
    @Watch("room", { deep: true, immediate: true }) handler(room: any) {
        room.water && this.$set(this.form, "water", room.water);
        room.electric && this.$set(this.form, "electric", room.electric);
        if (room.rent) {
            this.$set(this.form, "deposit", room.rent);
            this.$set(this.form, "rent", room.rent);
        }
        room.remark && this.$set(this.form, "remark", room.remark);
    }
    onConfirm(date: Date) {
        this.$set(this.form, "stay_at", _date.date("Y-m-d", date.getTime()));
        this.showCalendar = false;
    }
    async onSubmit() {
        await roomStay({ ...this.form, id: this.room.id });
        Toast.success("入住成功");
        this.show = false;
        this.$emit("success");
    }
}
