
import { Component, Vue, Inject } from "vue-property-decorator";
import { roomDetail } from "@/api";
import Stay from "@/components/Stay.vue";
import Rent from "@/components/Rent.vue";
import Cost from "@/components/Cost.vue";
import Expire from "@/components/Expire.vue";
import RentUpdate from "@/components/RentUpdate.vue";
import Remark from "@/components/Remark.vue";

@Component({
    components: { Stay, Rent, Cost, Expire, RentUpdate, Remark },
})
export default class Detail extends Vue {
    @Inject() passwordInit!: () => void;
    private id = "";
    private room = {};

    private stayVisible = false;
    private rentVisible = false;
    private costVisible = false;
    private expireVisible = false;
    private rentUpdateVisible = false;
    private remarkVisible = false;
    created() {
        this.passwordInit();
        this.id = this.$route.params.id;
        this.roomDetail();
    }
    async roomDetail() {
        const { data } = await roomDetail(this.id);
        this.room = data;
    }
    keepTextStyle(val: string) {
        return (val + "").replace(/\n/g, "<br/>");
    }
}
